import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { getCurrentUser } from './getCurrentUser';
import { db } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

export async function hideSearch(searchId: string): Promise<void> {
  try {
    const user = getCurrentUser();

    if (!user) {
      throw new Error('No user found');
    }

    const searchDocRef = doc(db, 'searches', searchId);
    const searchDocSnap = await getDoc(searchDocRef);

    if (searchDocSnap.exists()) {
      await updateDoc(searchDocRef, { hidden: true });
    } else {
      throw new Error('Search document not found');
    }
  } catch (error) {
    alertError(error, { function: 'hideSearch' }, { searchId });
  }
}
